import React from 'react';
import MyRoutes from '../myRoutes/myRoutes';
import  'bootstrap/dist/css/bootstrap.min.css' ;


function userMain() {
  return (
    <>
    
      <MyRoutes/>
    
    </>
  )
}

export default userMain;